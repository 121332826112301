:root[data-seed] {
  --seed-alert-dialog-backdrop-background: var(--seed-semantic-color-overlay-dim);
  --seed-alert-dialog-content-background: var(--seed-semantic-color-paper-dialog);
  --seed-alert-dialog-title-color: var(--seed-scale-color-gray-900);
  --seed-alert-dialog-description-color: var(--seed-scale-color-gray-900);

  --seed-alert-dialog-content-max-width: 272px;
  --seed-alert-dialog-text-container-padding-top: 20px;
  --seed-alert-dialog-text-container-horizontal-padding: 20px;
  --seed-alert-dialog-button-container-horizontal-padding: 16px;
  --seed-alert-dialog-button-container-padding-bottom: 20px;
  --seed-alert-dialog-content-horizontal-margin: 32px;
  
  --seed-alert-dialog-content-border-radius: 16px;

  --seed-alert-dialog-containers-gap: 16px;
  --seed-alert-dialog-texts-gap: 6px;
  --seed-alert-dialog-buttons-gap: 8px;

  --seed-alert-dialog-secondary-action-neutral-background: var(--seed-semantic-color-secondary-low);

  --seed-alert-dialog-nonpreferred-second-action-color: var(--seed-scale-color-gray-700);
}
