._1byi4n70 {
  display: flex;
}
._1byi4n71 {
  align-items: center;
}
._1byi4n72 {
  -webkit-user-select: none;
}
._1byi4n74 {
  font-size: var(--seed-semantic-typography-body-l1-bold-font-size);
  font-weight: var(--seed-semantic-typography-body-l1-bold-font-weight);
  line-height: var(--seed-semantic-typography-body-l1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-body-l1-bold-letter-spacing);
}
._1byi4n75 {
  font-size: var(--seed-semantic-typography-body-l1-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-l1-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-l1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-l1-regular-letter-spacing);
}
._1byi4n76 {
  font-size: var(--seed-semantic-typography-body-l2-bold-font-size);
  font-weight: var(--seed-semantic-typography-body-l2-bold-font-weight);
  line-height: var(--seed-semantic-typography-body-l2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-body-l2-bold-letter-spacing);
}
._1byi4n77 {
  font-size: var(--seed-semantic-typography-body-l2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-l2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-l2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-l2-regular-letter-spacing);
}
._1byi4n78 {
  font-size: var(--seed-semantic-typography-body-m1-bold-font-size);
  font-weight: var(--seed-semantic-typography-body-m1-bold-font-weight);
  line-height: var(--seed-semantic-typography-body-m1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m1-bold-letter-spacing);
}
._1byi4n79 {
  font-size: var(--seed-semantic-typography-body-m1-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m1-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m1-regular-letter-spacing);
}
._1byi4n7a {
  font-size: var(--seed-semantic-typography-body-m2-bold-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-bold-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-bold-letter-spacing);
}
._1byi4n7b {
  font-size: var(--seed-semantic-typography-body-m2-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m2-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m2-regular-letter-spacing);
}
._1byi4n7c {
  font-size: var(--seed-semantic-typography-caption1-bold-font-size);
  font-weight: var(--seed-semantic-typography-caption1-bold-font-weight);
  line-height: var(--seed-semantic-typography-caption1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-bold-letter-spacing);
}
._1byi4n7d {
  font-size: var(--seed-semantic-typography-caption1-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption1-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption1-regular-letter-spacing);
}
._1byi4n7e {
  font-size: var(--seed-semantic-typography-caption2-bold-font-size);
  font-weight: var(--seed-semantic-typography-caption2-bold-font-weight);
  line-height: var(--seed-semantic-typography-caption2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-caption2-bold-letter-spacing);
}
._1byi4n7f {
  font-size: var(--seed-semantic-typography-caption2-regular-font-size);
  font-weight: var(--seed-semantic-typography-caption2-regular-font-weight);
  line-height: var(--seed-semantic-typography-caption2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-caption2-regular-letter-spacing);
}
._1byi4n7g {
  font-size: var(--seed-semantic-typography-h1-font-size);
  font-weight: var(--seed-semantic-typography-h1-font-weight);
  line-height: var(--seed-semantic-typography-h1-line-height);
  letter-spacing: var(--seed-semantic-typography-h1-letter-spacing);
}
._1byi4n7h {
  font-size: var(--seed-semantic-typography-h2-font-size);
  font-weight: var(--seed-semantic-typography-h2-font-weight);
  line-height: var(--seed-semantic-typography-h2-line-height);
  letter-spacing: var(--seed-semantic-typography-h2-letter-spacing);
}
._1byi4n7i {
  font-size: var(--seed-semantic-typography-h3-font-size);
  font-weight: var(--seed-semantic-typography-h3-font-weight);
  line-height: var(--seed-semantic-typography-h3-line-height);
  letter-spacing: var(--seed-semantic-typography-h3-letter-spacing);
}
._1byi4n7j {
  font-size: var(--seed-semantic-typography-h4-font-size);
  font-weight: var(--seed-semantic-typography-h4-font-weight);
  line-height: var(--seed-semantic-typography-h4-line-height);
  letter-spacing: var(--seed-semantic-typography-h4-letter-spacing);
}
._1byi4n7k {
  font-size: var(--seed-semantic-typography-label1-bold-font-size);
  font-weight: var(--seed-semantic-typography-label1-bold-font-weight);
  line-height: var(--seed-semantic-typography-label1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-label1-bold-letter-spacing);
}
._1byi4n7l {
  font-size: var(--seed-semantic-typography-label1-regular-font-size);
  font-weight: var(--seed-semantic-typography-label1-regular-font-weight);
  line-height: var(--seed-semantic-typography-label1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label1-regular-letter-spacing);
}
._1byi4n7m {
  font-size: var(--seed-semantic-typography-label2-bold-font-size);
  font-weight: var(--seed-semantic-typography-label2-bold-font-weight);
  line-height: var(--seed-semantic-typography-label2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-label2-bold-letter-spacing);
}
._1byi4n7n {
  font-size: var(--seed-semantic-typography-label2-regular-font-size);
  font-weight: var(--seed-semantic-typography-label2-regular-font-weight);
  line-height: var(--seed-semantic-typography-label2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label2-regular-letter-spacing);
}
._1byi4n7o {
  font-size: var(--seed-semantic-typography-label3-bold-font-size);
  font-weight: var(--seed-semantic-typography-label3-bold-font-weight);
  line-height: var(--seed-semantic-typography-label3-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-label3-bold-letter-spacing);
}
._1byi4n7p {
  font-size: var(--seed-semantic-typography-label3-regular-font-size);
  font-weight: var(--seed-semantic-typography-label3-regular-font-weight);
  line-height: var(--seed-semantic-typography-label3-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label3-regular-letter-spacing);
}
._1byi4n7q {
  font-size: var(--seed-semantic-typography-label4-bold-font-size);
  font-weight: var(--seed-semantic-typography-label4-bold-font-weight);
  line-height: var(--seed-semantic-typography-label4-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-label4-bold-letter-spacing);
}
._1byi4n7r {
  font-size: var(--seed-semantic-typography-label4-regular-font-size);
  font-weight: var(--seed-semantic-typography-label4-regular-font-weight);
  line-height: var(--seed-semantic-typography-label4-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label4-regular-letter-spacing);
}
._1byi4n7s {
  font-size: var(--seed-semantic-typography-label5-bold-font-size);
  font-weight: var(--seed-semantic-typography-label5-bold-font-weight);
  line-height: var(--seed-semantic-typography-label5-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-bold-letter-spacing);
}
._1byi4n7t {
  font-size: var(--seed-semantic-typography-label5-regular-font-size);
  font-weight: var(--seed-semantic-typography-label5-regular-font-weight);
  line-height: var(--seed-semantic-typography-label5-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-regular-letter-spacing);
}
._1byi4n7u {
  font-size: var(--seed-semantic-typography-label6-bold-font-size);
  font-weight: var(--seed-semantic-typography-label6-bold-font-weight);
  line-height: var(--seed-semantic-typography-label6-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-label6-bold-letter-spacing);
}
._1byi4n7v {
  font-size: var(--seed-semantic-typography-label6-regular-font-size);
  font-weight: var(--seed-semantic-typography-label6-regular-font-weight);
  line-height: var(--seed-semantic-typography-label6-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label6-regular-letter-spacing);
}
._1byi4n7w {
  font-size: var(--seed-semantic-typography-subtitle1-bold-font-size);
  font-weight: var(--seed-semantic-typography-subtitle1-bold-font-weight);
  line-height: var(--seed-semantic-typography-subtitle1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle1-bold-letter-spacing);
}
._1byi4n7x {
  font-size: var(--seed-semantic-typography-subtitle1-regular-font-size);
  font-weight: var(--seed-semantic-typography-subtitle1-regular-font-weight);
  line-height: var(--seed-semantic-typography-subtitle1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle1-regular-letter-spacing);
}
._1byi4n7y {
  font-size: var(--seed-semantic-typography-subtitle2-bold-font-size);
  font-weight: var(--seed-semantic-typography-subtitle2-bold-font-weight);
  line-height: var(--seed-semantic-typography-subtitle2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle2-bold-letter-spacing);
}
._1byi4n7z {
  font-size: var(--seed-semantic-typography-subtitle2-regular-font-size);
  font-weight: var(--seed-semantic-typography-subtitle2-regular-font-weight);
  line-height: var(--seed-semantic-typography-subtitle2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-subtitle2-regular-letter-spacing);
}
._1byi4n710 {
  font-size: var(--seed-semantic-typography-title1-bold-font-size);
  font-weight: var(--seed-semantic-typography-title1-bold-font-weight);
  line-height: var(--seed-semantic-typography-title1-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-title1-bold-letter-spacing);
}
._1byi4n711 {
  font-size: var(--seed-semantic-typography-title1-regular-font-size);
  font-weight: var(--seed-semantic-typography-title1-regular-font-weight);
  line-height: var(--seed-semantic-typography-title1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-title1-regular-letter-spacing);
}
._1byi4n712 {
  font-size: var(--seed-semantic-typography-title2-bold-font-size);
  font-weight: var(--seed-semantic-typography-title2-bold-font-weight);
  line-height: var(--seed-semantic-typography-title2-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-title2-bold-letter-spacing);
}
._1byi4n713 {
  font-size: var(--seed-semantic-typography-title2-regular-font-size);
  font-weight: var(--seed-semantic-typography-title2-regular-font-weight);
  line-height: var(--seed-semantic-typography-title2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-title2-regular-letter-spacing);
}
._1byi4n714 {
  font-size: var(--seed-semantic-typography-title3-bold-font-size);
  font-weight: var(--seed-semantic-typography-title3-bold-font-weight);
  line-height: var(--seed-semantic-typography-title3-bold-line-height);
  letter-spacing: var(--seed-semantic-typography-title3-bold-letter-spacing);
}
._1byi4n715 {
  font-size: var(--seed-semantic-typography-title3-regular-font-size);
  font-weight: var(--seed-semantic-typography-title3-regular-font-weight);
  line-height: var(--seed-semantic-typography-title3-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-title3-regular-letter-spacing);
}
@supports (-webkit-touch-callout: none) {
  ._1byi4n72 {
    -webkit-touch-callout: none;
  }
}