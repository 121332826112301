._1ga0u620 {
  height: 407px;
}
._1ga0u621 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 407px;
  left: 0;
  position: absolute;
  top: 0;
  transition: .5s ease-out;
  width: 100%;
  z-index: 2;
}
._1ga0u622 {
  opacity: 1;
  visibility: visible;
}
._1ga0u623 {
  opacity: 0;
  visibility: hidden;
}
._1ga0u624 {
  height: 129px;
  justify-content: center;
  margin-top: 28px;
  width: 340px;
}
._1ga0u625 {
  padding: 27.964px 99.981px 7.247px 99.5px;
  box-sizing: content-box;
}
._1ga0u626 {
  flex-direction: column;
  align-self: stretch;
  gap: 12px;
  padding: 24px 20px;
}
._1ga0u629 {
  align-self: stretch;
  padding: 12px 16px 8px 16px;
}
._1ga0u62a {
  flex-direction: column;
  align-self: stretch;
  padding: 6px 0px;
}