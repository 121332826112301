@import-normalize;

* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  color: #212529;
  cursor: default;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  user-select: none;
  -webkit-touch-callout: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  /* gray 900 */
  color: inherit;
  text-decoration: none;
}

input {
  appearance: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 0;
}

button {
  outline: none;
  appearance: none;
  background: transparent;
  border: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: var(--colors-gray900) !important;
}
