:root[data-seed] {
  /* Color */
  --seed-chip-enabled-background: none;
  --seed-chip-enabled-border-color: var(--seed-semantic-color-divider-2);
  --seed-chip-enabled-color: var(--seed-scale-color-gray-900);

  --seed-chip-pressed-background: var(--seed-semantic-color-gray-pressed);
  --seed-chip-pressed-border-color: var(--seed-semantic-color-divider-2);
  --seed-chip-pressed-color: var(--seed-scale-color-gray-900);

  --seed-chip-disabled-background: none;
  --seed-chip-disabled-border-color: var(--seed-semantic-color-divider-2);
  --seed-chip-disabled-color: var(--seed-scale-color-gray-400);

  --seed-chip-emphasized-enabled-background: var(--seed-scale-color-gray-800);
  --seed-chip-emphasized-enabled-border-color: transparent;
  --seed-chip-emphasized-enabled-color: var(--seed-scale-color-gray-00);

  --seed-chip-emphasized-disabled-background: var(--seed-scale-color-gray-400);
  --seed-chip-emphasized-disabled-color: var(--seed-scale-color-gray-00);
  --seed-chip-emphasized-disabled-border-color: transparent;

  --seed-chip-emphasized-pressed-background: var(--seed-scale-color-gray-700);
  --seed-chip-emphasized-pressed-border-color: transparent;
  --seed-chip-emphasized-pressed-color: var(--seed-scale-color-gray-00);

  /* Layout */
  --seed-chip-border-radius: 9999px;

  --seed-chip-medium-height: 38px;
  --seed-chip-medium-padding-x: 14px;
  --seed-chip-medium-padding-y: 10px;
  --seed-chip-medium-gap: 4px;
  --seed-chip-medium-prefix-icon-size: 1rem;
  --seed-chip-medium-suffix-icon-size: 0.875rem;

  --seed-chip-small-height: 32px;
  --seed-chip-small-padding-x: 12px;
  --seed-chip-small-padding-y: 6px;
  --seed-chip-small-gap: 4px;
  --seed-chip-small-prefix-icon-size: 0.875rem;
  --seed-chip-small-suffix-icon-size: 0.875rem;

  --seed-chip-group-gap: 8px;

  --seed-chip-count-enabled-color: var(--seed-scale-color-gray-600);
  --seed-chip-count-pressed-color: var(--seed-scale-color-gray-700);
  --seed-chip-count-disabled-color: var(--seed-scale-color-gray-400);
}
