._1q6dj2j0 {
  --stackflow-plugin-basic-ui-background-color: #fff;
  --stackflow-plugin-basic-ui-dim-background-color: rgba(0, 0, 0, 0.15);
  --stackflow-plugin-basic-ui-transition-duration: 0s;
  --stackflow-plugin-basic-ui-computed-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-border-color: rgba(0, 0, 0, 0.07);
  --stackflow-plugin-basic-ui-app-bar-border-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-border-size: 1px;
  --stackflow-plugin-basic-ui-app-bar-height: 3.5rem;
  --stackflow-plugin-basic-ui-app-bar-height-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-min-height: 3.5rem;
  --stackflow-plugin-basic-ui-app-bar-icon-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-icon-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-text-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-text-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-background-color: #fff;
  --stackflow-plugin-basic-ui-app-bar-background-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-overflow: hidden;
  --stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top: 0px;
  --stackflow-plugin-basic-ui-bottom-sheet-border-radius: 1rem;
}
._1q6dj2j1 {
  --stackflow-plugin-basic-ui-background-color: #fff;
  --stackflow-plugin-basic-ui-dim-background-color: rgba(0, 0, 0, 0.15);
  --stackflow-plugin-basic-ui-transition-duration: 0s;
  --stackflow-plugin-basic-ui-computed-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-border-color: rgba(0, 0, 0, 0.07);
  --stackflow-plugin-basic-ui-app-bar-border-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-border-size: 0.5px;
  --stackflow-plugin-basic-ui-app-bar-height: 2.75rem;
  --stackflow-plugin-basic-ui-app-bar-height-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-min-height: 2.75rem;
  --stackflow-plugin-basic-ui-app-bar-icon-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-icon-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-text-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-text-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-background-color: #fff;
  --stackflow-plugin-basic-ui-app-bar-background-color-transition-duration: 0s;
  --stackflow-plugin-basic-ui-app-bar-overflow: hidden;
  --stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top: 0px;
  --stackflow-plugin-basic-ui-bottom-sheet-border-radius: 1rem;
}
._1q6dj2j5 {
  pointer-events: none;
}