.seed-chip {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;

  cursor: pointer;

  border: none;
  text-transform: none;
  text-align: start;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  border-radius: var(--seed-chip-border-radius);
}

/* Layout */
.seed-chip[data-size="medium"] {
  height: var(--seed-chip-medium-height);
  min-height: var(--seed-chip-medium-height);
  padding: var(--seed-chip-medium-padding-y) var(--seed-chip-medium-padding-x);
  gap: var(--seed-chip-medium-gap);
}

.seed-chip [data-part="prefix"] {
  display: inline-flex;
}

.seed-chip [data-part="prefix"] > svg {
  flex: 1;
}

.seed-chip [data-part="suffix"] {
  display: inline-flex;
}

.seed-chip [data-part="suffix"] > svg {
  flex: 1;
}

.seed-chip[data-size="medium"] [data-part="prefix"] {
  width: var(--seed-chip-medium-prefix-icon-size);
  height: var(--seed-chip-medium-prefix-icon-size);
}

.seed-chip[data-size="medium"] [data-part="suffix"] {
  width: var(--seed-chip-medium-suffix-icon-size);
  height: var(--seed-chip-medium-suffix-icon-size);
}

.seed-chip[data-size="small"] {
  height: var(--seed-chip-small-height);
  min-height: var(--seed-chip-small-height);
  padding: var(--seed-chip-small-padding-y) var(--seed-chip-small-padding-x);
  gap: var(--seed-chip-small-gap);
}

.seed-chip[data-size="small"] [data-part="prefix"] {
  width: var(--seed-chip-small-prefix-icon-size);
  height: var(--seed-chip-small-prefix-icon-size);
}

.seed-chip[data-size="small"] [data-part="suffix"] {
  width: var(--seed-chip-small-suffix-icon-size);
  height: var(--seed-chip-small-suffix-icon-size);
}

/* Color */
.seed-chip[data-variant="default"] {
  background: var(--seed-chip-enabled-background);
  border: 1px solid var(--seed-chip-enabled-border-color);
  color: var(--seed-chip-enabled-color);
}

.seed-chip[data-variant="default"][data-pressed] {
  background: var(--seed-chip-pressed-background);
  border-color: var(--seed-chip-pressed-border-color);
  color: var(--seed-chip-pressed-color);
}

.seed-chip[data-variant="default"][data-disabled] {
  background: var(--seed-chip-disabled-background);
  border-color: var(--seed-chip-disabled-border-color);
  color: var(--seed-chip-disabled-color);
}

.seed-chip[data-variant="emphasized"] {
  background: var(--seed-chip-emphasized-enabled-background);
  border: 1px solid var(--seed-chip-emphasized-enabled-border-color);
  color: var(--seed-chip-emphasized-enabled-color);
}

.seed-chip[data-variant="emphasized"][data-pressed] {
  background: var(--seed-chip-emphasized-pressed-background);
  border-color: var(--seed-chip-emphasized-pressed-border-color);
  color: var(--seed-chip-emphasized-pressed-color);
}

.seed-chip[data-variant="emphasized"][data-disabled] {
  background: var(--seed-chip-disabled-background);
  border-color: var(--seed-chip-disabled-border-color);
  color: var(--seed-chip-disabled-color);
}

.seed-chip[data-variant="emphasized"][data-selected][data-disabled] {
  background: var(--seed-chip-emphasized-disabled-background);
  border-color: var(--seed-chip-emphasized-disabled-border-color);
  color: var(--seed-chip-emphasized-disabled-color);
}

/* count */
.seed-chip [data-part="count"] {
  color: var(--seed-chip-count-enabled-color);
}

.seed-chip[data-pressed] [data-part="count"] {
  color: var(--seed-chip-count-pressed-color);
}

.seed-chip[data-disabled] [data-part="count"] {
  color: var(--seed-chip-count-disabled-color);
}

/* Group */
.seed-chip-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--seed-chip-group-gap);
}
