/* Root */
.seed-avatar {
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: center;

  border-radius: var(--seed-avatar-border-radius);

  width: var(--seed-avatar-size);
  height: var(--seed-avatar-size);

  --seed-avatar-size: var(--seed-avatar-medium-size);
  --seed-badge-size: var(--seed-avatar-medium-badge-size);
}

.seed-avatar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: var(--seed-avatar-border-radius);
  box-shadow: inset 0 0 0 var(--seed-avatar-border-width)
    var(--seed-avatar-border-color);
}

.seed-avatar-group .seed-avatar::before {
  box-shadow: inset 0 0 0 var(--seed-avatar-border-width)
    var(--seed-avatar-in-group-border-color);
}

.seed-avatar[data-size="xxsmall"] {
  --seed-avatar-size: var(--seed-avatar-xxsmall-size);
  --seed-badge-size: var(--seed-avatar-xxsmall-badge-size);
}

.seed-avatar[data-size="xsmall"] {
  --seed-avatar-size: var(--seed-avatar-xsmall-size);
  --seed-badge-size: var(--seed-avatar-xsmall-badge-size);
}

.seed-avatar[data-size="small"] {
  --seed-avatar-size: var(--seed-avatar-small-size);
  --seed-badge-size: var(--seed-avatar-small-badge-size);
}

.seed-avatar[data-size="medium"] {
  --seed-avatar-size: var(--seed-avatar-medium-size);
  --seed-badge-size: var(--seed-avatar-medium-badge-size);
}

.seed-avatar[data-size="large"] {
  --seed-avatar-size: var(--seed-avatar-large-size);
  --seed-badge-size: var(--seed-avatar-large-badge-size);
}

/* Image */
.seed-avatar-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--seed-avatar-border-radius);
}

/* Fallback */
.seed-avatar [data-part="fallback"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--seed-avatar-border-radius);
}

/* Badge */
.seed-avatar-badge {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--seed-badge-size);
  height: var(--seed-badge-size);
  top: calc(
    var(--seed-avatar-size) / 2 + var(--seed-avatar-size) / 2.828 -
      var(--seed-badge-size) / 2
  );
  left: calc(
    var(--seed-avatar-size) / 2 + var(--seed-avatar-size) / 2.828 -
      var(--seed-badge-size) / 2
  );
  border-radius: var(--seed-avatar-badge-border-radius);
  background: var(--seed-avatar-badge-background);
  border: var(--seed-avatar-badge-border);
}

/* Group */
.seed-avatar-group {
  display: flex;
  align-items: center;
}

/* Group */
.seed-avatar-group[data-top-item="firstItem"] {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.seed-avatar-group[data-group-size="xxsmall"][data-top-item="firstItem"] .seed-avatar:not(:last-child) {
  margin-inline-start: var(--seed-avatar-group-xxsmall-spacing);
}
.seed-avatar-group[data-group-size="xxsmall"][data-top-item="lastItem"] .seed-avatar:not(:first-child) {
  margin-inline-start: var(--seed-avatar-group-xxsmall-spacing);
}

.seed-avatar-group[data-group-size="xsmall"][data-top-item="firstItem"] .seed-avatar:not(:last-child) {
  margin-inline-start: var(--seed-avatar-group-xsmall-spacing);
}
.seed-avatar-group[data-group-size="xsmall"][data-top-item="lastItem"] .seed-avatar:not(:first-child) {
  margin-inline-start: var(--seed-avatar-group-xsmall-spacing);
}

.seed-avatar-group[data-group-size="small"][data-top-item="firstItem"] .seed-avatar:not(:last-child) {
  margin-inline-start: var(--seed-avatar-group-small-spacing);
}
.seed-avatar-group[data-group-size="small"][data-top-item="lastItem"] .seed-avatar:not(:first-child) {
  margin-inline-start: var(--seed-avatar-group-small-spacing);
}

.seed-avatar-group[data-group-size="medium"][data-top-item="firstItem"] .seed-avatar:not(:last-child) {
  margin-inline-start: var(--seed-avatar-group-medium-spacing);
}
.seed-avatar-group[data-group-size="medium"][data-top-item="lastItem"] .seed-avatar:not(:first-child) {
  margin-inline-start: var(--seed-avatar-group-medium-spacing);
}

.seed-avatar-group[data-group-size="large"][data-top-item="firstItem"] .seed-avatar:not(:last-child) {
  margin-inline-start: var(--seed-avatar-group-large-spacing);
}
.seed-avatar-group[data-group-size="large"][data-top-item="lastItem"] .seed-avatar:not(:first-child) {
  margin-inline-start: var(--seed-avatar-group-large-spacing);
}

/* Excess */
.seed-avatar-group-excess {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  background: var(--seed-avatar-group-excess-background);
  color: var(--seed-avatar-group-excess-color);
  border: var(--seed-avatar-group-excess-border);
  border-radius: var(--seed-avatar-border-radius);
}

.seed-avatar-group-excess[data-group-excess-size="xxsmall"] {
  width: var(--seed-avatar-xxsmall-size);
  height: var(--seed-avatar-xxsmall-size);
  margin-inline-start: var(--seed-avatar-group-xxsmall-spacing);
}

.seed-avatar-group-excess[data-group-excess-size="xsmall"] {
  width: var(--seed-avatar-xsmall-size);
  height: var(--seed-avatar-xsmall-size);
  margin-inline-start: var(--seed-avatar-group-xsmall-spacing);
}

.seed-avatar-group-excess[data-group-excess-size="small"] {
  width: var(--seed-avatar-small-size);
  height: var(--seed-avatar-small-size);
  margin-inline-start: var(--seed-avatar-group-small-spacing);
}

.seed-avatar-group-excess[data-group-excess-size="medium"] {
  width: var(--seed-avatar-medium-size);
  height: var(--seed-avatar-medium-size);
  margin-inline-start: var(--seed-avatar-group-medium-spacing);
}

.seed-avatar-group-excess[data-group-excess-size="large"] {
  width: var(--seed-avatar-large-size);
  height: var(--seed-avatar-large-size);
  margin-inline-start: var(--seed-avatar-group-large-spacing);
}
