._1qiymj70 {
  border: none;
  transition: .5s ease-out;
}
._1qiymj71 {
  opacity: 1;
  visibility: visible;
}
._1qiymj72 {
  opacity: 0;
  visibility: hidden;
}