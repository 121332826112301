:root[data-seed] {
  /* Root */
  --seed-avatar-border-radius: 100%;
  --seed-avatar-border-color: var(--seed-scale-color-gray-alpha-50);
  --seed-avatar-border-width: 1px;

  --seed-avatar-in-group-border-color: var(--seed-semantic-color-paper-default);

  --seed-avatar-xxsmall-size: 20px;
  --seed-avatar-xsmall-size: 24px;
  --seed-avatar-small-size: 36px;
  --seed-avatar-medium-size: 48px;
  --seed-avatar-large-size: 64px;

  /* Badge */
  --seed-avatar-badge-border-radius: 100%;
  --seed-avatar-badge-border: none;
  --seed-avatar-badge-background: var(--seed-scale-color-gray-00);

  --seed-avatar-xxsmall-badge-size: 10px;
  --seed-avatar-xsmall-badge-size: 12px;
  --seed-avatar-small-badge-size: 20px;
  --seed-avatar-medium-badge-size: 24px;
  --seed-avatar-large-badge-size: 24px;

  /* Group */
  --seed-avatar-group-xxsmall-spacing: -4px;
  --seed-avatar-group-xsmall-spacing: -5px;
  --seed-avatar-group-small-spacing: -6px;
  --seed-avatar-group-medium-spacing: -8px;
  --seed-avatar-group-large-spacing: -10px;

  /* Group Excess */
  --seed-avatar-group-excess-background:  var(--seed-semantic-color-paper-default);
  --seed-avatar-group-excess-border: 1px dashed var(--seed-scale-color-gray-400);
  --seed-avatar-group-excess-color: var(--seed-scale-color-gray-600);
}
